import commonApi from '../_EXT_API'
import {singleProxy} from '@/global/js/exportEnter'

class login extends commonApi{
    constructor(opt){
        opt = opt || {}
        let localKey = 'preview'
        let name = 'preview'
        opt = {localKey, name}
        super(opt)
    }

    async getSubjectExercise(opt){
        let {exercise_id} = opt
        opt = opt || {}
        let options = {
            method: "get",
            exercise_id,
            data:{}
        }
        return await this.requestAction('getSubjectExercise', options)
    }
    async getCourseExercise(opt){
        let {exercise_id} = opt
        opt = opt || {}
        let options = {
            method: "get",
            exercise_id,
            data:{}
        }
        return await this.requestAction('getCourseExercise', options)
    }
    async getPuzzleExercise(opt){
        let {exercise_id} = opt
        opt = opt || {}
        let options = {
            method: "get",
            exercise_id,
            data:{}
        }
        return await this.requestAction('getPuzzleExercise', options)
    }
}

export default singleProxy(login)