<!--  -->
<template>
    <div class='page-task-action'>
        <exercise :sgf="sgf" 
        @gameover="gameover" 
        :awardCount.sync="awardCount"
        ref="exercise">
            <span slot="title-text" 
            class="text-normal max-width">第{{currentExNum}}题</span>

            <div class="ex-hand-fun" slot="mask-extend" v-if="!nextExerciseData">
                <dia-button type="confirm" shape="square" class="flex-center" @click="restart">
                    <span class="text-normal-a">重新开始</span>
                </dia-button>
            </div>
        </exercise>
    </div>
</template>

<script>
    import apiClass from '@/API/page/preview'
    import exercise from '@/components/exercise/exercise.vue'

    export default {
        name: '',
        props: {},
        data() {
            return {
                _exercise: null,
                api: new apiClass(),
                exerciseInfo: {},
                exerciseState: false,
                exerciseAwardInfo: {},
                routeData: location.pathname.substring(location.pathname.lastIndexOf("/") + 1,location.pathname.length),
            };
        },
        methods: {
            dataInit(){
                this.exerciseState = false
            },
            async dataLoad(){
                await this.exerciseListInit()
            },
            async exerciseListInit(){
                let {exerciseId} = this
                let opt = {
                    exercise_id: exerciseId,
                }
                let res = await this.api.getCourseExercise(opt)
                if(!res) return
                this.exerciseInfo = res
            },
            eventSet(){
                this.eventOff()
                this._common.eventSet('previewCourseBackFun', e => {
                    this.back()
                })
            },
            eventOff(){
                this._common.eventRemove('previewCourseBackFun')
            },
            back(){
                window.history.go(-1)
            },
            exerciseComInit(){
                let ele = this.$refs['exercise']
                if(!ele) return
                this._exercise = ele
                this.exerciseState = true
            },
            async gameover(opt){
                this._exercise.resultMaskOpen()
                this.gameCount()
            },
            async gameCount(){
                this.$refs['exercise'].stopCountdown()
                // await this._common.settimeout(1500)
                // this.back()
            },
            setParams(items){
                return this._common.setParams.call(this, items)
            },
            async restart(){
                let info = this._common.deepCopy(this.exerciseInfo)
                this.exerciseInfo = {}
                await this._common.nextTick.call(this)
                this.exerciseInfo = info
            }
        },
        created() {
            this.eventSet()
        },
        components: {
            exercise,
        },
        computed: {
            awardCount(){
                return this.exerciseAwardInfo.awardCount || 0
            },
            routeDataCul(){
                let {routeData} = this
                return this._common.routeDataCul(routeData)
            },
            sgf(){
                let {
                    exerciseInfo: {exerciseContent: sgf} = {}
                } = this
                return sgf
            },
            nextExerciseData(){
                let rd = null
                return rd
            },
            currentExNum(){
                let {exerciseNo = 0} = this.exerciseInfo || {}
                return exerciseNo
            },
            exerciseId(){
                let {exerciseId} = this.routeDataCul
                return exerciseId
            },
            chapterId(){
                let {chapterId} = this.routeDataCul
                return chapterId
            }
        },
        watch: {
            $route: {
                handler(){
                    this.routeData = this.$route.params.routeData
                },
                deep: true,
                immediate: true
            },
            sectionId: {
                handler(val, oldval){
                    if(!val || val == oldval) return
                    this.dataLoad()
                },
                deep: true,
                immediate: true
            },
            routeData: {
                handler(val, oldval){
                    this.dataLoad()
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {
            this.exerciseComInit()
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.eventOff()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' scoped>
</style>